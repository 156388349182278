/* You can add global styles to this file, and also import other style files */
body{
    background-color: rgba(242,242,242,1)!important;
    font-size: 14px;
    /* font-family: Frutiger-Roman,Helvetica,Arial,sans-serif; */
    font-family: Optum Sans, 'Arial', sans-serif; 
    font-size: 14px;
    /* rgba(245, 245, 245, 1) */
    color: #222222;
}
.inputFieldLabel{
    color: #444444 !important;
}
figure{
    margin: 0px !important;
}
.modal-content{
    border-radius: 0px;
}
.btn{
    border-radius: 2px;
    font-size: 1.15em !important;
}

.btn:focus{
    border: 2px solid #002677;
}
button.invisiblebtn{
    border-color: none;
    outline-color: none;
    padding: 0;
}
button.invisiblebtn.btn-link:focus{
    border: 1px solid #002677;
}
/* button.invisiblebtn:focus{
    border-width: 1px;
    border-style: solid;
    outline: 0!important;
    box-shadow: none;
} */
button.invisiblebtn:focus{
    border: 1px solid;
    box-shadow: 0 6px 12px rgba(0,0,0,.35)!important;
}
button.invisiblebtn:hover{
    /* box-shadow: 0 6px 12px rgba(0,0,0,.35)!important; */
    box-shadow: 0 0 0 2px #fff,
    0 0 0 4px #002677;
}
button.invisiblebtn:not(:hover):not(:focus){
    border:none !important;
    outline: none !important;
}
.btn.btn-primary.btn-primary-link{
    /* height: 44px; */
    color: #002677 !important;
    background-color: transparent !important;
    font-size: 16px;
    font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
    border:2px solid #002677;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}
.btn.btn-primary.btn-primary-link:focus{
    background: #002677 !important;
    transition: background .25s ease;
    -webkit-transition: background .25s ease;
    -o-transition: background .25s ease;
    -moz-transition: background .25s ease;
    color: #fff!important;
    outline-style: none!important;
    box-shadow: 0 0!important;
}
.btn.btn-primary.btn-primary-link:hover{
    background: #002677 !important;
    transition: background .25s ease;
    -webkit-transition: background .25s ease;
    -o-transition: background .25s ease;
    -moz-transition: background .25s ease;
    color: #fff!important;
    outline-style: none!important;
    box-shadow: 0 0!important;
}
.btn:not(.btn-primary-link):not(.btn-secondary):not(.btn-link) :focus{
    box-shadow: none !important;
}
.btn.btn-primary:not(.invisiblebtn){
    background-color: #002677;
}
.btn.btn-primary:not(.invisiblebtn):hover{
    box-shadow: 0 0 0 2px #fff,
    0 0 0 4px #002677 !important;
}
.btn.btn.btn-primary:not(.invisiblebtn):focus{
    box-shadow: 0 0 0 2px #fff,
    0 0 0 4px #002677 !important;
}
.btn.btn-secondary:not(.invisiblebtn){
    border-radius: 2px;
    border: 2px solid #002677;
    font-size: 16px;
    color: #002677!important;
    background-color: #fff;
    text-decoration:none;
    text-align: center;
    outline: 1px solid #002677;
}
.btn.btn-secondary:not(.invisiblebtn):focus
{
    outline-style: dashed;
    outline-color: #002677;
    outline-width: 3px;
    border: none;
    box-shadow: 0 0 0 2px #fff,
    0 0 0 4px #002677 !important;
}
.btn.btn-secondary:not(.invisiblebtn):hover
{
    background: #002677;
    transition: background .25s ease;
    color: #fff!important;
    outline-style: none!important;
    box-shadow: 0 0 0 2px #fff,
    0 0 0 4px #002677 !important;
}

.btn.btn-secondary-outline:not(.invisiblebtn){
    border-radius: 2px;
    border: 2px solid #222;
    color: #222!important;
    background-color: #fff;
    text-decoration:none;
    text-align: center;
    /* outline: 1px solid #002677; */
}
.btn.btn-secondary-outline:not(.invisiblebtn):focus
{
    outline-style: dashed;
    outline-color: #002677;
    border: 2px solid #002677;
    outline-width: 2px;
    /* border: none; */
    box-shadow: 0 0 0 2px #fff,
    0 0 0 4px #002677 !important;
}
.btn.btn-secondary-outline:not(.invisiblebtn):hover
{
    background: #222;
    transition: background .25s ease;
    color: #fff!important;
    outline-style: none!important;
    box-shadow: 0 0 0 2px #fff,
    0 0 0 4px #222 !important;
}
.paragraph, p {
    font-family:'ArialMT', 'Arial', sans-serif;
    font-size: 1.15em;
    color: #636363;
    font-weight: 400;
    line-height: 1.25;
    /*letter-spacing: 1.56px;
    word-spacing: 2.08px; */
}
.paragraph.large, p.large{
    font-size: 1.25em;
    margin: 0px;
    color: #222222;
    line-height: 1.5;
    letter-spacing: 1.92x;
    word-spacing: 2.56px;
}
h1{
    font-family: 'ArialMT', 'Arial', sans-serif;
    color:#002677;
    font-size: 2.5em;
    padding:0px;
    margin: 0px;
}
h2{
    font-family: 'ArialMT', 'Arial', sans-serif;
    color: #002677;
    font-size: 1.75em;
    padding:0px;
    margin: 0px;
}
h3,h4{
    font-family: 'Arial-BoldMT', 'Arial Bold','Arial', sans-serif;
    color: #002677;
    font-size: 1.25em;
    padding:0px;
    margin: 0px;
}
a{
    color: #316BBE!important;
    font-size: 1em !important;
}
a.btn.btn-link,
button.btn.btn-link{
    color: #316BBE;
    text-decoration: none !important;
}
a.btn.btn-link:hover,
button.btn.btn-link:hover{
    text-decoration: underline !important;
}
.alert{
    padding: 15px 20px;
    font-family: 'ArialMT',Arial,sans-serif;
    font-size: 16px;
    vertical-align: middle;
    border-radius: 0px;
    margin-bottom: 0px;
}
.alert.shadow{
    box-shadow: 0 4px 6px rgb(0 0 0 / 20%);
}
.alert i:first-child{
    font-size: 24px;
    vertical-align: middle;
    margin-right: 10px;
}
.alert i:last-child{
    font-size: 16px;
    vertical-align: middle;
    cursor: pointer;
}
.alert span{
    width: 94%;
    display: inline-block;
    vertical-align: middle;
}
table tr td p{
    margin: 0px !important;
    display: contents;
    color:#222;
}
.fontbold{
    font-family: Frutiger-Bold,Helvetica,Arial,sans-serif!important;
}
.font{
    font-family: 'ArialMT',Arial,sans-serif;
    color: #222222;
    font-weight: 700;
}
.alert.resultsflag{
    font-family: Frutiger-Bold!important;
    border-radius: 25px;
    padding: 6px 8px;
    text-align: center;
    font-size: 13px;
    font-weight: 400!important;
    margin: 0;
    border-color: #fff!important;
}
.alert-danger {
    background-color: #fff4f4!important;
    color: #c60f13;
}
.alert-success{
    background-color:#e4f3e4!important;
    color:#007a4b;
}
.alert-primary{
    color: #E5F8FB !important;
    /* background-color:#e4f3e4!important; */
}
select.dropdown:not(.inpError){
    border:solid 2px gray;
}
select.dropdown:not(.inpError):focus
{
    outline-width: 3px;
    outline-style: dashed;
    outline-color: #002677;
    box-shadow: 0 0 12px 0 #002677;

    background-color: #fff;
    height: 44px;
    border: none;
}

.label{
    font-family:  Arial Bold, Arial, sans-serif; 
    font-weight: 700; 
    color: #636363;
}

input[type="text"]:not(.inpError){
    border:solid 2px gray;
}

input[type="text"]:not(.inpError):focus
{
    /* input[type="text"]:focus{ */
    outline:solid 2px #002677;
    border: none;
    outline-style: dashed;
    box-shadow: 0 0 10px #002677;
    background-color: #fff;
}
input[type="checkbox"]:not(.inpError):focus,input[type="checkbox"]:not(.inpError):hover
{
    /* input[type="text"]:focus{ */
    outline:solid 2px #002677;
    border: none !important;
    outline-style: dashed;
    box-shadow: 0 0 10px #002677;
}
select.responsive{
    width: auto !important;
}
.inpError,
.inpError:focus{
    border: none;
    outline:2px solid #c60f13;
    box-shadow: 0 0 10px #c60f13;
    background-color: #fff4f4;
}
*:has(>datepicker) {
    padding:0px !important;
}
.content-card{
    -moz-box-shadow:  0 4px 6px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow:  0 4px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    columns: #444444 !important;
    /* overflow: auto; */
}
.content-card .card-header,
.content-card .card-content{
    padding:26px;
    border-radius: 4px 4px 0 0;
}
.content-card .card-header:not(.noborder){
    border-bottom: 2px solid #ddd;
}
.content-card .card-footer{
    background-color: rgba(242,246,249,1);
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding:26px;
}
.nopadding{
    padding:0px !important;
}
.nomargin{
    margin:0px !important;
}
.notfavorite{
    background-image: url(assets/images/notfavorite.png);
    width:24px;
    height: 24px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    display: inline-block;
}
.favorite{
    background-image: url(assets/images/favorite.png);
    width:24px;
    height: 24px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    display: inline-block;
}
.radioInput.disabled,
.radioInput.disabled *{
    opacity: 0.8;
}
.radioInput input[type="radio"]{
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
    cursor: pointer;
}
@font-face {
	font-family: "Frutiger-Roman";
	font-weight: 400;
    font-style: normal;
	/* src: url("./assets/fonts/FrutigerLTStd-Roman.otf"); */
	
	src: url('./assets/fonts/FrutigerRoman.eot'); 
    src: url('./assets/fonts/FrutigerRoman.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/FrutigerRoman.woff') format('woff'),
         url('./assets/fonts/FrutigerRoman.ttf')  format('truetype'),
         url('./assets/fonts/FrutigerRoman.svg') format('svg');
}
@font-face {
	font-family: "Frutiger-Light";
	font-weight: 300;
 	font-style: normal;
	/* src: url("./assets/fonts/FrutigerLTStd-Light.otf"); */
	
	src: url('./assets/fonts/FrutigerLight.eot');
    src: url('./assets/fonts/FrutigerLight.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/FrutigerLight.woff') format('woff'),
         url('./assets/fonts/FrutigerLight.ttf')  format('truetype'),
         url('./assets/fonts/FrutigerLight.svg') format('svg');
}
@font-face {
	font-family: "Frutiger-Bold";
	font-weight: 600;
    font-style: normal;
	/* src: url("./assets/fonts/FrutigerLTStd-Bold.otf"); */
	
	src: url('./assets/fonts/FrutigerBold.eot');
    src: url('./assets/fonts/FrutigerBold.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/FrutigerBold.woff') format('woff'),
         url('./assets/fonts/FrutigerBold.ttf')  format('truetype'), 
         url('./assets/fonts/FrutigerBold.svg') format('svg');
}
.savedMembersModal,
.modalDafault{
    min-height: 300px;
    overflow:auto;
}
.textcapitalize{
    text-transform: capitalize;
}
.noHeight{
    height: 0px;
    padding: 0px !important;
    margin: 0px !important;
}

i.fa-close:hover{
    cursor: pointer;
}
.fa.fa-ban,
.fa.fa-exclamation-circle,
.fa.fa-exclamation-triangle.error{
    font-size: 30px;
    color: #c60f13;
    position: relative;
}
.fa-exclamation-triangle:not(.error){
    color: rgb(143,101,43);
    font-size:35px;
}
.fa-angle-right,
.fa-angle-left{
    font-size: 2em !important; 
    color: #0470c5;
    /* position: relative;
    top: 6px; */
}
.fa.fa-check-circle{
    font-size: 50px;
    color: green;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
    .modal-sm,
    .modal-lg,
    .modal-xl{
        max-width: 85%;
    }

    td p{
        font-size: 0.625em;
    }

    .btn.btn-primary.btn-primary-link{
        font-size: .75em;
    }

    /* .paragraph.large, p.large{
        font-size: .75em;
    } */

    
    a{
        font-size: 0.75em !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .modal-sm,
    .modal-lg,
    .modal-xl{
        max-width: 75%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
    .modal-sm,
    .modal-lg,
    .modal-xl{
        max-width: 65%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
    .modal-sm{
        max-width: 50%;
    }
    .modal-lg{
        max-width:65%;
    }
    .modal-xl{
        max-width: 75%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
    .modal-sm{
        max-width: 40%;
    }
    .modal-lg{
        max-width:60%;
    }
    .modal-xl{
        max-width: 75%;
    }    
}
.bigloader i.fa-spin{
    font-size: 50px !important;
}

.required{
color: #222 !important;
}

.optional{
color: #636363 !important;
}

.underline{
    padding: 10px !important;
    text-decoration:underline;
    border: none;
    background: transparent;
    color: #316BBE;
  }
  
  .underline:hover {
    padding: 10px !important;
    border: 2px solid #002677 !important;
    border-radius: 25px;
}
.drugDetailTable{
    background-color: #fff !important;
}

.drugDetailSection{
    background-color: #F5F5F5 !important;
}
.drugDetailRow{
    border-bottom: 2px solid #FF612B;
}
.bordered{
    /* box-shadow: 0 0 0 2px #fff, */
    /* 0 0 0 4px #000 !important; */

    box-shadow: 0 0 0 2px #fff, 0 0 0 3px #000 !important
}

.borderbottom{
    border-bottom: 2px solid #dee2e6 !important;
}
/********************************EPA module related styles start***************************************/

/********************************EPA module related styles end***************************************/

::-webkit-scrollbar {
    display: none;
}